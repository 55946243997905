import { BASE_URL } from "./base__url";
import axios from "axios";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
let router = useRouter();
const service = axios.create({
  baseURL: "/",
});

service.interceptors.request.use(
  function (config) {
    // console.log(config);
    const token = sessionStorage.getItem("token");
    if (token) config.headers["token"] = token
      return config;
  },
  function (error) {
    router.push("/login");
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  function (response) {
    if (response.data.code == 20000) {
      message.error("token已失效！请重新登录。", 1, () => {
        sessionStorage.clear();
        location.reload();
      });
    } else {
      const res = response.data;
      if (res.type == "application/vnd.ms-excel") {
        return res;
      }
      if (res.data.token) sessionStorage.setItem("token", res.data.token);
      return res;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default service;

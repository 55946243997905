<template>
  <router-view />
</template>
<script setup>
import * as echarts from "echarts";
import { provide } from "vue";
provide("ec", echarts);
</script>
<style lang="less">
:root {
  --global-primary-color: green; /* 默认的主色 */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1245px;
  width: 100%;
  height: 100%;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

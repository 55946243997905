import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/font_4473603_lznblizk9s9/iconfont.css'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import * as echarts from 'echarts'


const app = createApp(App)
app.echarts = echarts
NProgress.configure({
    easing:'ease',
    speed:500,
    showSpinner:false,
    trickleSpeed:200,
    minimum:0.3
  })
app.use(store).use(Antd).provide('axios',axios).use(router).mount('#app')

<template>
  <div style="min-width: 1440px;">
    <div class="title">
      <div class="left">
        <img style="width: 100px;" src="../assets/images/logo.png" alt="">
      </div>
      
			<div class="reight">
        <img style="margin-right: 40px;width: 24px;height: 24px;" src="../assets/images/download.png" alt="">
        <a-popover placement="bottomRight">
          <template #content>
          <div class="dl" style="text-align: center;cursor: pointer;" @click="out">退出登录</div>
            </template>
          <div>
            <span style="font-size: 16px;color: #1D2129;">Hi, {{ formState.contactName }}<img style="margin-left: 12px;width: 32px;height: 32px;" src="../assets/images/toux.png" alt=""></span>
          </div>
        </a-popover>
      </div>
    </div>
    <a-layout>
      <a-layout-sider v-model:collapsed="collapsed" collapsible style="padding-top: 18px;" :class="{leftl: cbl, left2: !cbl}">
        <a-menu theme="light" v-model:selectedKeys="selectedKeys" mode="inline">
          <router-link to="/">
            <a-menu-item key="1">
              <span style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6df;</span>
              <span style="vertical-align: middle;" :class="{'yc': zt}">首页</span>
            </a-menu-item>
          </router-link>
          <router-link to="/dataReport">
            <a-menu-item key="2">
              <span style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6da;</span>
              <span style="vertical-align: middle;" :class="{'yc': zt}">数据报表</span>
            </a-menu-item>
          </router-link>
          <router-link to="/dataReports">
            <a-menu-item key="3" v-if="show">
              <span style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6dc;</span>
              <span style="vertical-align: middle;" :class="{'yc': zt}">实时数据</span>
            </a-menu-item>
          </router-link>
          <router-link to="/comparison">
            <a-menu-item key="4" v-if="show">
              <span style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6db;</span>
              <span style="vertical-align: middle;" :class="{'yc': zt}">数据对比</span>
            </a-menu-item>
          </router-link>
        </a-menu>
        <div class="jtbx"><img @click="onimg" :class="{jt: true, jt2: !cbl}" src="../assets/images/jt.png" alt=""></div>
      </a-layout-sider>
      <a-layout>
        <a-layout-content>
          <a-breadcrumb>
          </a-breadcrumb>
          <div :style="{ height: '94vh', overflowY: 'auto'}">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>
<script setup>
import {
  PieChartOutlined,
  DesktopOutlined
} from '@ant-design/icons-vue';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from "vue-router"
import { OutLogin } from "../sereive/index"
const route = useRoute()
let router = useRouter()
let collapsed = ref(false)
let selectedKeys = ref(['1'])
let i = ref(true)
var formState = ref({})
let show = ref(true)
let zt = ref(false)
let cbl = ref(true)

function out() {
  OutLogin({username: route.query.username}).then((res) => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("formState");
    router.push('/login')
  })
}

onMounted(async () => {
  formState.value = JSON.parse(sessionStorage.getItem("formState"))
  selectedKeys.value.length = 0
  selectedKeys.value = [`${route.meta.key}`]
  if (JSON.parse(sessionStorage.getItem("isShowRtData")) == 0) {
    show.value = false
  }
})
function click() {
  i.value = !i.value
}


function onimg() {
  zt.value = !zt.value
  cbl.value = !cbl.value
}
</script>
<style scoped lang="less">
a{
  color: rgba(34, 35, 38, 0.95) !important;
  text-decoration: none !important;
}

/* 选中菜单状态 */
/* /deep/ .ant-menu-item-selected {
  background-color: pink !important;
  color: red !important;
} */
/* 设置未选中菜单项鼠标滑过样式 */
/* /deep/ .ant-menu-item-active {
  background-color: red !important;
  color: red !important;
} */

/* .aaaaaaaaa:hover .color-change{
  fill: red;
}
.color-change{
  width: 16px;
  height: 16px;
} */


#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.yc{
  display: none;
}
.site-layout .site-layout-background {
  background: #fff;
}

.el-card {
  transition: opacity 3s;
  opacity: 0;
  position: absolute;
  min-width: 220px;
  right: 2px;
  top: 108%;
  z-index: 100;
  box-sizing: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #262626;
}
.el-card2{
  display: none;
}

.el-card3 {
  opacity: 1;
}

.el-card_header {
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.el-card_header div p {
  font-size: 16px;
  margin-bottom: 3px;
}

.bgBox {
  width: 50px;
  height: 50px;
  border: .5px solid #ccc;
  border-radius: 50%;
  margin-right: 18px;
}

.but {
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.but:hover {
  background-color: rgb(245, 247, 249);
}

.title {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E5E6EB;
  width: 100%;
  height: 50px;
  background: #FFF;
  color: #28a5dc;
}

.left {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 50px;
}

.img1 {
  width: 83px;
  height: 38px;
  margin-right: 20px;
}

.img2 {
  height: 22px;
  width: 202px;
}

.reight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px;
  height: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.colLine {
  width: .5px;
  height: 16px;
  background: #79c3e4;
  margin: 5px;
}

.icon-bell {
  cursor: pointer;
  padding: 12px;
  position: relative;
}
.dl{
	padding: 6px 32px;
	text-align: center;
}
.dl:hover{
	background-color: #f5f7f9;
}

.username {
  padding: 15px 3px 15px 12px;
  cursor: pointer;
  color: #1D2129;
}

.ant-layout {
  height: 100% !important;
}
.jtbx{
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.jt{
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.jt2{
  transform: rotate(-180deg);
}
/deep/ .leftl{
  position: relative !important;
  width: 200px !important;
  flex: 0 0 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
}
/deep/ .left2{
  position: relative !important;
  width: 80px !important;
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
}
/deep/ .ant-layout-sider {
  background-color: #fff !important;
}

/deep/ .ant-layout-sider-trigger {
  position: absolute;
  background-color: rgb(230, 247, 255);
}

/deep/ .ant-layout-sider-trigger span svg {
  color: #bbb;
}
/deep/ .ant-menu-inline .ant-menu-item-selected::after{
  opacity: 0;
}
/deep/ .ant-layout-sider-trigger{
  display: none;
}
</style>